import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import logo from "../../assets/logos/pitchou footer.png"

export default function Footer() {

  const [isTabletOrLarger, setIsTabletOrLarger] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsTabletOrLarger(window.innerWidth >= 1024); // Taille de la tablette en pixels
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Appel initial pour définir l'état en fonction de la taille de l'écran actuelle

    return () => window.removeEventListener('resize', handleResize); // Nettoyage de l'écouteur d'événements lors du démontage du composant
  }, []);
  return (

    <footer className="w-full bg-secondary-dark relative">
      <img src={logo} alt="" width={60} height={60} className="absolute w-10 lg:w-14 lg:-top-[20px] -top-4 lg:-translate-y-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 m-auto p-0 border-none" />

      {isTabletOrLarger ? (
        <>
          <div className="text-center lg:pt-0 pt-5 lg:pb-5 pb-4">
            <div className=" lg:flex sm:flex-wrap md:flex-row lg:flex-row lg:h-48">
              <div className=" flex flex-col basis-1/3 m-auto">
                <div data-aos="zoom-in-down" className="title-container">
                  <h2 className="text-ternary-gold/80 uppercase font-bold text-sm">Liens utiles :</h2>
                </div>
                <div className="flex m-auto lg:pt-2">
                  <ul className="text-primary-light grid grid-cols-1 gap-x-5">
                    <li data-aos="fade-right" data-aos-delay="500" className="span-col-1">
                      <Link to="/">
                        <span className=" hover:underline underline-offset-4 " >Accueil</span>
                      </Link>
                    </li>
                    <li data-aos="fade-left" data-aos-delay="900" className="span-col-1">
                      <Link to="/menus">
                        <span className=" hover:underline underline-offset-4" >La Carte</span>
                      </Link>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="1300" className="span-col-1">
                      <Link to="/équipe">
                        <span className=" hover:underline underline-offset-4" >L'Équipe</span>
                      </Link>
                    </li>
                    <li data-aos="fade-left" data-aos-delay="1600" className="span-col-1">
                      <Link to="/traiteur">
                        <span className=" hover:underline underline-offset-4" >Traiteur</span>
                      </Link>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="2100" data-aos-offset="10" className="span-col-1">
                      <Link to="/contact">
                        <span className=" hover:underline underline-offset-4" >Contact</span>
                      </Link>
                    </li>
                    <li data-aos="fade-left" data-aos-delay="2500" data-aos-offset="10" className="span-col-1">
                      <Link to="/privatisation">
                        <span className=" hover:underline underline-offset-4" >Privatiser</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col justify-end md:basis-1/3 lg:pt-10">
                <Link to="/mentions-légales">
                  <span className="text-ternary-gold/80 uppercase font-bold text-lg">Mentions légales</span>
                </Link>
                <p className="sm:text-md my-2 text-primary-light">© Pitchou Grill & Bar, 2024 - Tous droits réservés
                </p>
              </div>
              <div className=" flex flex-col md:basis-1/3 justify-end">
                <h2 className="text-ternary-gold/80 uppercase font-bold text-sm pt-5">Retrouvez nous :</h2>
                <div className="flex flex-wrap justify-center md:flex-row p-4 text-primary-light">
                  <Link target="_blank" aria-label="facebook social account" to="https://www.facebook.com/pitchou.marcillac" className="relative w-10 h-10 mx-1 hover:animate-pulse hover:text-secondary-color">
                    <FaFacebookF className="absolute inset-2 text-2xl" />
                  </Link>
                  <Link target="_blank" aria-label="instagram social account" to="https://www.instagram.com/pitchou.marcillac/" className="relative w-10 h-10 mx-1 hover:animate-pulse hover:text-secondary-color">
                    <FaInstagram className="absolute inset-2 text-2xl" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-neutral-900 py-1 leading-8 m-auto text-center">
            <span className="text-secondary-light">
              Développé par
            </span>
            <span className="text-secondary-light animate-pulse">
              <a
                className="text-primary-light text-lg hover:underline underline-offset-4 ml-2"
                href="https://www.devyoh.com"
                target="_blanck"
                rel="noreferrer">
                Devyoh
              </a>
            </span>
          </div>
        </>

      ) : (
        <div className="text-center lg:pt-0 pt-5 lg:pb-5">
          <div className=" lg:flex sm:flex-wrap md:flex-row lg:flex-row lg:h-48">
            <div className=" flex flex-col basis-1/3 m-auto">
              <div data-aos="zoom-in-down" className="title-container">
                <h2 className="text-ternary-gold/80 uppercase font-bold text-lg mb-5">Liens utiles :</h2>
              </div>
              <div className="flex m-auto lg:pt-2">
                <ul className="text-primary-light grid grid-cols-1 gap-x-5 m-auto">
                  <li className="span-col-1 h-12" data-aos="fade-right" data-aos-delay="500">
                    <Link to="/">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >Accueil</span>
                    </Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="900" className="span-col-1 h-12">
                    <Link to="/menus">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >La Carte</span>
                    </Link>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="1300" className="span-col-1 h-12">
                    <Link to="/traiteur">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >Traiteur</span>
                    </Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="1600" className="span-col-1 h-12">
                    <Link to="/équipe">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >L'Équipe</span>
                    </Link>
                  </li>
                  <li data-aos="fade-right" data-aos-delay="2100" data-aos-offset="10" className="span-col-1 h-12">
                    <Link to="/contact">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >Contact</span>
                    </Link>
                  </li>
                  <li data-aos="fade-left" data-aos-delay="2500" data-aos-offset="10" className="span-col-1 h-12">
                    <Link to="/privatisation">
                      <span className=" hover:underline underline-offset-4 h-full text-xl" >Privatiser</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" flex flex-col md:basis-1/3 justify-end">
              <h2 className="text-ternary-gold/80 uppercase font-bold text-lg pt-5">Retrouvez nous :</h2>
              <div className="flex flex-wrap justify-center md:flex-row p-4 text-primary-light">
                <Link target="_blank" aria-label="facebook social account" to="https://www.facebook.com/pitchou.marcillac/" className="relative w-10 h-10 mx-1">
                  <FaFacebookF className="absolute inset-2 text-2xl" />
                </Link>
                <Link target="_blank" aria-label="instagram social account" to="https://www.instagram.com/pitchou.marcillac/" className="relative w-10 h-10 mx-1">
                  <FaInstagram className="absolute inset-2 text-2xl" />
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-end md:basis-1/3 lg:pt-10">
              <Link to="/mentions-légales">
                <span className="text-ternary-gold/80 uppercase font-bold text-lg">Mentions légales</span>
              </Link>
              <p className="text-base my-2 text-primary-light">© Pitchou Grill & Bar, 2024 <br /> Tous droits réservés
              </p>
            </div>
            <div className="bg-neutral-900 py-1 leading-8">
              <span className="text-secondary-light">
                Développé par
              </span>
              <span className="text-secondary-light animate-pulse">
                <a
                  className="text-primary-light text-lg hover:underline underline-offset-4 ml-2"
                  href="https://www.devyoh.com"
                  target="_blanck"
                  aria-label="developper author"
                  rel="noreferrer">
                  Devyoh
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
    </footer>

  );
}
