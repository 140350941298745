import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Faites défiler jusqu'en haut de la page à chaque changement d'emplacement
  }, [pathname]);

  return null; // Ce composant ne rend rien
};

export default ScrollToTop;
