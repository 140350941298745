import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppFooter from "./components/Footer/footer";
import AppHeader from "./components/Shared/AppHeader";
import ScrollToTop from '../src/hooks/ScrollToTop.jsx';
import "./styles/App.css";

const About = lazy(() => import("./pages/About/About.jsx"));
const Contact = lazy(() => import("./pages/Contact/Contact.jsx"));
const Home = lazy(() => import("./pages/Home/Home"));
const Privatisation = lazy(() => import("./pages/Presentation/Privatisation.jsx"));
const Traiteur = lazy(() => import("./pages/Presentation/TraiteurWithoutImage.jsx"));
const Team = lazy(() => import("./pages/Presentation/teamPage.jsx"));
const LegalMention = lazy(() => import("./pages/Presentation/Mentions.jsx"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound.jsx"));

function App() {
  useEffect(() => {
    // Vérifie si nous sommes sur la page d'accueil
    if (window.location.pathname === '/') {
      // Charger l'image uniquement si nous sommes sur la page d'accueil
      const image = new Image();
      image.src = 'images/marcillac.webp';
      image.onload = function() {
        console.log('Image marcillac.webp chargée avec succès.');
        // Ajoutez le code pour afficher ou manipuler l'image ici
      };
    }
  }, []);
  return (
    <>
      <Router>
        <ScrollToTop />
        <AppHeader />
        <Suspense fallback={""}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privatisation" element={<Privatisation />} />
            <Route path="/traiteur" element={<Traiteur />} />
            <Route path="/équipe" element={<Team />} />
            <Route path="/menus" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/mentions-légales" element={<LegalMention />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <AppFooter />
      </Router>
    </>
  );
}

export default App;
